import ApiClient from "./apiClient";
import { QueriesApi } from "./apiTypes";


const billingService = {
  getRemainingInfo:() => ApiClient.get('openstack/billing/credit_and_invoicing_info'),
  getSummary:() => ApiClient.get('billing/summary'),
  getOpenStackSummary:() => ApiClient.get('openstack/summary'),
  getInvoiceList:(queries:QueriesApi) => ApiClient.get('billing/invoices',{
    params:{
      ...queries,
    }
  }),
  getServices:(queries:QueriesApi) => ApiClient.get(`billing/services`, {
    params:{
      ...queries,
    }
  }),
  getInvoice:(invoiceId:any) => ApiClient.get(`billing/invoices/${invoiceId}`),
  payFromCredit:(invoiceId:string) => ApiClient.post(`billing/invoices/${invoiceId}/pay_from_credit_balance`),
  getService:(serviceId:string) => ApiClient.get(`billing/services/${serviceId}`),
  getUpgradeOption:(serviceId:string) => ApiClient.get(`billing/services/${serviceId}/upgrade_options`),
  executeAction:(serviceId:string, data:Object) => ApiClient.post(`billing/services/${serviceId}/execute_action`, data),
  getBillingHistory:() => ApiClient.get('openstack/billing'),
  getBillingById:(billingId:string) => ApiClient.get(`openstack/billing/${billingId}`),
  getUnpaidInvoices:(currency:string) => ApiClient.get('billing/invoices',{
    params: {
      page_size:1000,
      detailed_list:true,
      filtering:`status:unpaid+currency:${currency}`,
    }
  }),
  getRemainingDate:() => ApiClient.get('openstack/billing/credit_and_invoicing_info'),
  addCreditInvoice:(data:Object) => ApiClient.post(`billing/invoices/add_credit_invoice`,data),
  mergeInvoices:(data:any) => ApiClient.post(`billing/invoices/merge_invoices`, data),
  executeActionAllInstances:(serviceId:string|number, data:Object) => ApiClient.post(`billing/services/${serviceId}/execute_action`, data),
  getPaymentMethods:(data?:Object) => ApiClient.get(`billing/gateway/wayforpay/cards`),
  confirmPaymentMethod:(data?:Object) => ApiClient.post(`billing/gateway/wayforpay/confirm_code`, data),
  deletePaymentMethod:(data?:Object) => ApiClient.post(`billing/gateway/wayforpay/delete_card`, data),
  getAutopayConfig:() => ApiClient.get(`billing/gateway/wayforpay/auto_pay_config`),
  updateAutopayConfig:(data:Object) => ApiClient.get(`billing/gateway/wayforpay/auto_pay_config`, data),
  changeCardStatus:(data:Object) => ApiClient.post(`billing/gateway/wayforpay/change_activate_card_status`, data),
  addCreditFromPaymentMethod:(data:Object) => ApiClient.post(`billing/gateway/wayforpay/card_pay`, data),
}

export default billingService;