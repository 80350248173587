import styled from 'styled-components'

type CheckBoxProps = {
  active?:boolean,
  disabled?:boolean,
}
type ContainerProps = {
  customStyles?:any
}
export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  ${({customStyles}) => customStyles}
  &:hover{
    cursor:pointer;
  }
`

export const StyledCheckBox = styled.div<CheckBoxProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${({active}) => active ? '16px' : '14px'};
  max-width: ${({active}) => active ? '16px' : '14px'};
  height: ${({active}) => active ? '16px' : '14px'};
  border-radius: 4px;
  margin-right:10px;
  background-color: ${({theme, active, disabled}) => {
    return disabled ? `${theme.grey[200]}` : active ? `${theme.primary[600]}` : `${theme.palette[10]}`
  }};
  border:${({active, theme, disabled}) => {
    return disabled ? `1px solid ${theme.grey[400]}` : active ? `` : `1px solid ${theme.grey[400]}`
  }};
  &:hover{
    cursor:pointer;
  }
`



export const CheckBoxImg = styled.img`
  margin-right: 10px;
  min-width:16px;
  max-width:16px;
  height: 16px;
  &:hover{
    cursor: pointer;
  }
`