import React, { FC, useEffect, useState } from "react";
import {
  Container,
} from './styles'
import { Toggle, Button, Input, Counter, InfoBlock, TopProgress } from '../../../../../components'
import { useQueryParam, StringParam } from 'use-query-params';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
//ToDo Delete imports with files
import UbuntuIcon from './ubuntu-logo.png'
import UkFlag from './ukraine-flag.svg'
import { useLocalStorage } from "../../../../../hooks/useLocalStorage";
import { useInstance } from "../../../../../context/instanceContext";
import { instanceService, networkService, routerService } from "../../../../../api";
import { useUi } from "../../../../../context/ui.context";
import { useAuth } from "../../../../../context/user.context";
import { randomNameGenerator } from "../../../../../utils/create-random-name";

const FinallyCreate:FC = () => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  // const [configureData, setConfigureData] = useState<any>()
  // const [networkData, setNetworkData] = useState<any>()
  const [localLoading, setLocalLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const {setDataToLs, getDataToLs, removeItemFromLs} = useLocalStorage()
  const {viewAlert} = useUi()
  const {
    flavors,
    bootSource,
    sshKeys,
    networks,
    groups,
    pageLoading,
    configureData,
    networkData,
    setConfigureData,
    setNetworkData,
    backupData,
    setBackupData,
    externalNics
  } = useInstance()
  useEffect(() => {
    if(!configureData) {
      setConfigureData(getDataToLs({key:'instance.configure'}))
    }
    if(!networkData) {
      setNetworkData(getDataToLs({key:'instance.network'}))
    }
    if(!backupData) {
      setBackupData(getDataToLs({key:'instance.backup'}))
    }
    setLocalLoading(false)
  },[])
  const {user} = useAuth()
  const [step, setStep] = useQueryParam('step', StringParam);
  const flavor = flavors?.find((flavor:any) => flavor.value === configureData?.type)
  const configure = [
    {
      title:t("NAME_OF_INSTANCE"),
      value:configureData?.name,
    },
    {
      title:t("TYPE_OF_INSTANCE"),
      value:flavor?.name,
      options:[`${flavor?.vcpus} CPU`, `${flavor?.memory_gb} GB RAM`, `${flavor?.customItem.estimate}`]
    },
    // {
    //   title:t("PLACEMENT_REGION"),
    //   value:'ua-central-1',
    //   icon:UkFlag,
    // },
    {
      title:t("OS"),
      value:bootSource.find((boot:any) => boot.value === configureData?.os)?.label,
      icon:bootSource.find((boot:any) => boot.value === configureData?.os)?.icon,
    },
    {
      title:t("NUMBER_MACHINES"),
      value:configureData?.countMachines,
    },
    {
      title:t("STORAGE"),
      value: configureData?.storageType === 'local' ?
      `${flavors?.find((flavor:any) => flavor.value === configureData?.type)?.root_gb || ''} GB`
      :
        `${configureData?.volumeSize || ''} GB`
      
      // `${flavors.find((flavor:any) => flavor.value === configureData.type)?.root_gb} GB`,
    },
    // {
    //   title:t("DISK_TYPE"),
    //   value:'HDD',
    // },
  ]
  const network = [
    {
      title:t("NETWORK"),
      value:networks.find((network:any) => network.value === networkData.network)?.label,
    },
    {
      title:t("ASSIGN_EXTERNAL_IP"),
      value:networkData.assignFloating ? t("YES") : t("NO"),
    },
    {
      title:t("SSH_KEY"),
      value:sshKeys.find((sshKey:any) => sshKey.value === networkData.ssh)?.label || '-',
    },
    // {
    //   title:t("FIREWALL"),
    //   value:groups.find((group:any) => group.value === networkData.firewall)?.label || '-',
    //   left:true,
    //   // options:[groups.find((group:any) => group.value === networkData.firewall).label]

    // },
    // {
    //   title:t("ASSIGN_EXTERNAL_IP"),
    //   value:'Ubuntu 18.04',
    // },
  ]
  // const backup = [
  //   {
  //     title:t("ENABLE_BACKUP"),
  //     value:backupData?.useBackup ? t("YES") : t("NO"),
  //   },
  //   {
  //     title:t("NAME_BACKUP"),
  //     value:backupData?.name || '-',
  //   },
  //   {
  //     title:t("MAX_NUMBER_BACKUPS"),
  //     value:backupData?.rotation || '-',
  //   },
  //   {
  //     title:t("SCHEDULE"),
  //     options:backupData?.days?.sort((a:any,b:any) => a.id - b.id).map((day:any) => day?.title),
  //     left:true,
  //     value:<div style={{marginLeft:'6px'}}>{backupData?.hour >= 10 ? `${backupData?.hour}:00, GMT +3` : `0${backupData?.hour}:00, GMT +3`}</div>,
  //   },
  // ]
  const createArrForCount = (count?:any) => {
    let arr = []
    for(let i = 0; i < count; i++) {
      arr.push(i)
    }
    return arr
  }
  // const handleCreateBackupSchedule = async (instanceId:string) => {
  //   if(backupData?.days?.length === 0){
  //     return
  //   }
  //   if(backupData?.days?.length === 1){
  //     const data = {
  //       backup_name:backupData?.name,
  //       backup_type:'weekly',
  //       day: +backupData?.days?.[0]?.id,
  //       hour: +backupData?.hour,
  //       instance:instanceId,
  //       rotation:backupData?.rotation,
  //       run_at:null,
  //     }
  //     return await instanceService.createInstanceBackupSchedule(data)
  //   } else if(backupData?.days?.length === 7){
  //     const data = {
  //       backup_name:backupData?.name,
  //       backup_type:'daily',
  //       hour: +backupData?.hour,
  //       instance:instanceId,
  //       rotation:backupData?.rotation,
  //       run_at:null,
  //     }
  //     return await instanceService.createInstanceBackupSchedule(data)
  //   } else if (backupData?.days?.length > 1) {
  //     let promises:any[] = []

  //     backupData?.days.map((day:any) => {
  //       const data = {
  //         backup_name:backupData?.name,
  //         backup_type:'weekly',
  //         day: +day?.id,
  //         hour: +backupData?.hour,
  //         instance:instanceId,
  //         rotation:backupData?.rotation,
  //         run_at:null,
  //       }
  //       // data.name = `${name}-${index+1}`
  //       let promise = instanceService.createInstanceBackupSchedule(data)
  //       promises.push(promise)
  //     })
     

  //     return await  Promise.all(promises)
  //   }
  // }
  const handleCreateInstance = async () => {
    setLoading(true);
    const bootSourceWindows = bootSource.find((source:any) => source.id === configureData.os)?.os_distro === 'windows';
    const countMachines = +configureData?.countMachines > 1 ? createArrForCount(+configureData?.countMachines) : [null];
    const createInstance = async (data:any) => {
      try {
        const res = await instanceService.createInstance(data);
        await setLoadingComplete(true);
        await setTimeout(() => {
          setLoadingComplete(false);
          setLoading(false);
          removeItemFromLs('instance.configure');
          removeItemFromLs('instance.network');
          setConfigureData(null);
          setNetworkData(null);
          navigate('/instances');
        }, 1000);
      } catch (e:any) {
        setLoading(false);
        setLoadingComplete(false);
        if (e?.response?.data?.detail) {
          viewAlert({
            severity: 'error',
            message: e.response.data.detail
          });
        }
      }
    };

    const createNetworkAndInstance = async () => {
      try {
        const network = await networkService.createNetwork({
          admin_state_up: true,
          description: '',
          name: randomNameGenerator({ name: 'network', findArr: [] }),
          region: configureData.region,
        });

        const subnet = await networkService.createSubnet({
          name: randomNameGenerator({ name: 'subnet', findArr: [] }),
          cidr: `10.0.1.0/24`,
          ip_version: '4',
          setGatewayIp: 'default',
          enable_dhcp: true,
          dns_nameservers: [],
          host_routes: [],
          network_mask: 0,
          network_address_source: 'manually',
          allocation_pools: [],
          network_id: network.data.id,
        });

        const routerOptions = await routerService.createOptionsRouter();
        //@ts-ignore
        const routerName:string = randomNameGenerator({ name: 'router', findArr: [] });
        const dataRouter = {
          admin_state_up: true,
          description: '',
          external_network_id: routerOptions?.data?.external_networks?.find((net:any) => net.name === 'external_network')?.id,
          name: routerName,
          region: configureData.region,
        };

        const res = await routerService.checkRouter(routerName);
        if (!res.data.router) {
          const router = await routerService.createRouter(dataRouter);
          const normRouter = await routerService.checkRouter(router.data.name);
          const subData = { ip: '', subnet: subnet.data.id };
          await routerService.addInterfaceToRouter(normRouter.data.router, subData);
          await Promise.all(countMachines.map((_, index) => {
            const instanceData = { ...data, name: countMachines?.length === 1 ? data.name :`${data.name}-${index + 1}`, nics: [network.data.id] };
            return instanceService.createInstance(instanceData);
          }));
          await setLoadingComplete(true);
          await setTimeout(() => {
            setLoadingComplete(false);
            setLoading(false);
            removeItemFromLs('instance.configure');
            removeItemFromLs('instance.network');
            setConfigureData(null);
            setNetworkData(null);
            navigate('/instances');
          }, 1000);
        }
      } catch (e:any) {
        setLoading(false);
        setLoadingComplete(false);
        if (e?.response?.data?.detail) {
          viewAlert({
            severity: 'error',
            message: e.response.data.detail,
          });
        }
      }
    };

    const data:any = {
      tags: configureData.tags,
      name: configureData.name,
      flavor: configureData.type,
      region: configureData.region,
      client: user.user.active_client,
      boot_source: {
        cinder_az: null,
        create_new_volume: configureData.storageType !== 'local',
        delete_on_termination: true,
        source_id: configureData.os,
        // source_type: "image",
        source_type: bootSource.find((boot:any) => boot.value === configureData?.os)?.bootSource,
        volume_type: configureData.storageType
      },
      root_password: bootSourceWindows ? 'Mockpassword11!' : configureData.root_password,
      nics: [networkData.network],
      ssh_keys: `[${[networkData.ssh]}]`,
    };

    if (configureData.storageType !== 'local') {
      data.boot_source.volume_size = configureData?.volumeSize;
    }

    if (networkData.network === 'create-network') {
      await createNetworkAndInstance();
    } else {
      await Promise.all(countMachines.map((_, index) => {
        const instanceData = { ...data, name: countMachines?.length === 1 ? data.name :`${data.name}-${index + 1}` };
        return createInstance(instanceData);
      }));
    }
  };


  if(pageLoading || localLoading) return <TopProgress loading={pageLoading || localLoading}/>
  return(
    <Container data-test-id='container-finally-instance' >
      <InfoBlock  title={t("CONFIGURE_INSTANCE")} data={configure}/>
      <InfoBlock  title={t("NETWORK_SETTINGS")} data={network}/>
      {/* <InfoBlock  title={t("BACKUP_SETTINGS")} data={backup}/> */}
      <div style={{display:'flex', marginTop:'30px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{marginRight:"10px"}}
          onClick={handleCreateInstance}
          loading={loading}
          loadingComplete={loadingComplete}
          title={t("CREATE")}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={() => setStep("network")}
          title={t("BACK")}
        />
      </div>
    </Container>
  )
}

export default FinallyCreate
