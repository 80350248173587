import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Input from "../../input";
import { HeadLine3 } from "../../typography/styled";
import { useAuth } from "../../../context/user.context";
import Toggle from "../../toggle";
import Select from "../../select";
import { billingService } from "../../../api";

            
type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(data:any) => void;
  alertTitle:string;
  instance?:any;
  loading?:boolean;
}  

const AddCredit:FC<ModalProps> = (props) => {
  const {
    closeModal,
    closeModalCancel,
    alertTitle,
    instance,
    loading
  } = props
  const [t] = useTranslation()
  const [isPaymentMethod, setIsPaymentMethod] = useState<boolean>(false)
  const [paymentMethod, setPaymentMethod] = useState<any>(null)
  const [paymentMethods, setPaymentMethods] = useState<any[]>([])
  const [modalLoading, setModalLoading] = useState<boolean>(true)
  const [initialValues, setInitialValues] = useState<any>({
    amount:'',
  })
  const onSubmit = (values:any) => {
    closeModal({...values, isPaymentMethod, paymentMethod})
  }
  useEffect(() => {
    billingService.getPaymentMethods()
    .then((res) => {
      setModalLoading(false)
      setPaymentMethods(res.data.cards.map((paymentMethod:any) => {
        return {
          ...paymentMethod,
          value: paymentMethod.id,
          label: paymentMethod.pan
        }
      }))
    })
    .catch((err) => {
      setModalLoading(false)
      console.log('err modal', err);
    })
  }, [])
  const {user} = useAuth()
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      amount: yup.string().required(`${t("REQUIRED_FIELD")}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginLeft:'10px', marginTop:'10px'}}>
          {t("ADD_CREDIT")}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        <Input
          required
          title={`${t('AMOUNT_IN')} ${user.user.clients[0]?.currency}`} 
          fieldName='amount'
          placeholder={''}
          type='number'
          containerCustom={{width:"100%"}}
          onBlur={handleBlur}
          {...formikProps}
        />
        <Toggle title={t("PAY_BY_PAYMENT_METHOD")} value={isPaymentMethod} onChange={() => setIsPaymentMethod(!isPaymentMethod)}/>
        {isPaymentMethod &&
          <Select 
            data={paymentMethods}
            selectedValue={paymentMethod}
            onChange={(value) => setPaymentMethod(value)}
            title={`${t("CHOOSE_CARD")}`}
            customStyles={{width:"100%"}}
          />
        }
        <div style={{display:"flex", gap:'6px', marginTop:'10px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            title={t("ADD_CREDIT")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default AddCredit