import React, {FC, useState, useEffect} from "react";
import {
  Container,
  TitleWrapper,
  SubTitleWrapper,
  AutoPayContainer
} from './styles'
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { Button, CheckBox, Input, ModalVariables, Table, TableSkeleton, Toggle, TopProgress, Typography } from "../../../../components";
import { billingService } from "../../../../api";
import { timeConverterMMDDYYHHMM } from "../../../../utils/dates";
import { StatusText } from "../overview/styles";
import { HeadLine1 } from "../../../../components/typography/styled";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../../../utils/display-price";
import Skeleton from 'react-loading-skeleton'
import { useTheme } from "styled-components";
import { handleStatusName } from "../../../../utils/handle-status-name";
// const crypto = require('crypto');
import CryptoJS from 'crypto-js';
import { useAuth } from "../../../../context/user.context";
import { verify } from "crypto";
import { icons } from "../../../../icons";
import { useUi } from "../../../../context/ui.context";
import {
  StateWrapper,
  GreenDot,
  Greentext,
} from "../../../Instances/styles";
import * as yup from 'yup';
import { useFormik } from 'formik';

  //@ts-ignore
var wayforpay = new window.Wayforpay(); 	
const PaymentMethods:FC = () => {
  const theme:any = useTheme()
  const {
    user,
  } = useAuth()
  const {viewAlert} = useUi()
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [ordering, setOrdering] = useQueryParam('ordering', StringParam);
  const [pageSize, setPageSize] = useQueryParam('page_size', NumberParam)
  const [paginationData, setPaginationData] = useState<any>()
  const [invoices, setInvoices] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [t] = useTranslation()
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [posBalance, setPosBalance] = useState<boolean>(false)
  const [suspendPay, setSuspendPay] = useState<boolean>(false)
  const [completeProforma, setCompleteProforma] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [isPaymentMethod, setIsPaymentMethod] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [cardConf, setCardConf] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>('')
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [selectedCard, setSelectedCard] = useState<any>()
  const [initialValues, setInitialValues] = useState<any>({
    amount:'',
  })

  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  const _deleteCard = async () => {
    setLoading(true);
    try {
      // if (allChecked) {
      //   await Promise.all(images.map((item:any) => imageService.deleteImage(item.id)));
      //   _updateImages();
      //   setAllChecked(false);
      //   setChecked([]);
      // } else if (checked?.length > 0) {
      //   await Promise.all(checked.map((item) => imageService.deleteImage(item.id)));
      //   _updateImages();
      //   setChecked([]);
      // } else if (selectedImage) {
      //   await imageService.deleteImage(selectedImage.id);
      //   _updateImages();
      // }
      await billingService.deletePaymentMethod({id:selectedCard.id})
    } catch (e:any) {
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
      // setChecked([]);
    } finally {
      setViewModal(false);
      setActiveAnimation(false);
      setLoading(false);
    }
  };
  const closeModal = async (data:any) => {
    let res:any;
    try {
      setLoading(true);
      switch (modalType) {
        case 'confirm-payment-method':
          res = await billingService.confirmPaymentMethod(data)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'delete':
          _deleteCard()
          break; 
      }
      if(modalType !== 'delete'){
        setViewModal(false)
        setActiveAnimation(false)
      }
    } catch (e: any) {
      console.log('e',e);
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
    } finally {
      if(modalType !== 'delete'){
        setLoading(false);
      }
    }
  };
  const getInvoices = () => {
    setLoading(true)
    // billingService.getAutopayConfig()
    // .then((res) => {
      
    // })
    // .catch((e) => {
    //   console.log('error', e);
    // })
    billingService.getPaymentMethods({
      page:page || 1,
      page_size:pageSize || 10,
      ordering:ordering || '-issue_date',
    })
    .then((res) => {
      //...res.data.cards_awaiting_code_verification, ...
      setInvoices(res.data.cards.map((paymentMethod:any) => {
        return {
          ...paymentMethod,
          created_at:timeConverterMMDDYYHHMM(paymentMethod.created_at),
          id:paymentMethod.pan,
          firstId:paymentMethod.id,
          status:(
            <StateWrapper>
              <GreenDot active={paymentMethod.active ? 'active' : 'blocked'}/>
              <Greentext active={paymentMethod.active ? 'active' : 'blocked'}>
                {paymentMethod.active ? t('ACTIVE') : t('BLOCKED')}
              </Greentext>
            </StateWrapper>
          ),
          verify:!paymentMethod?.active ? (
            <Button
              title={t("VERIFY")}
              onClick={() => {
                setSelectedCard(paymentMethod)
                openModal('confirm-payment-method')}}
              variant={'primary'}
              size='small'
            />
          ) : null
        }
      }))
      setPaginationData(res.data)
      setPageLoading(false)
      setLoading(false)
    })
    .catch((e) => {
      console.log('error', e);
    })
  }
  useEffect(() => {
    
    getInvoices()
    const interval = setInterval(() => {
      getInvoices()
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  },[page,
    ordering,
    pageSize,])
  const items = [
    {
      title:t("EDIT"),
      id:'3',
      icon:icons.Edit,
      onClick: (card:any) => {
        setSelectedCard(card)
        // openModal('delete')
      },
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:(card:any) => {
        openModal('delete')
        setAlertTitle(t("PAYMENT_METHOD"))
        setAlertSubTitle([card])
        setSelectedCard(card)
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  const ColumnsInvoices = [
    {
      title:'ID',
      key:'firstId'
    },
    {
      title:t("CARDPAN"),
      key:'pan',
    },
    {
      title:t("STATUS"),
      key:'status',
    },
    {
      title:t("DATE_ADDED"),
      key:'created_at'
    },
    {
      title:'',
      key:'verify'
    },
    {
      title:'',
      key:'props'
    },
  ]
  console.log('user',user);
  function generateSignature(obj:any) {
    let signatureString = '';
    const keys = Object.keys(obj).filter((key) =>
      key !== 'holdTimeout' &&
      key !== 'clientFirstName' &&
      key !== 'clientLastName' &&
      key !== 'clientEmail' &&
      key !== 'clientPhone' &&
      key !== 'clientCountry' &&
      key !== 'merchantAuthType' &&
      key !== 'merchantTransactionSecureType' &&
      key !== 'transactionType' &&
      key !== 'merchantTransactionType' &&
      key !== 'apiVersion' &&
      key !== 'serviceUrl'
    );
  
    keys.forEach((key, index) => {
        if (index > 0) {
            signatureString += ';';
        }
        const value = obj[key];
        // Если значение является массивом, преобразуем его в строку, разделяя элементы точкой с запятой
        if (Array.isArray(value)) {
            signatureString += value.join(';');
        } else {
            signatureString += value;
        }
    });
    console.log('signatureString', signatureString);
    // Применение хэш-функции HMAC MD5
    return CryptoJS.HmacMD5(signatureString, '401622deee143dcf6acc5c8e8e63b246d034fa36').toString(CryptoJS.enc.Hex);
  }

  const pay = function () { 	
    const data:any = {
      "merchantAccount": "netengi_ua",
      "merchantAuthType": "SimpleSignature",
      "merchantDomainName": "cp.netengi.com",
      "merchantTransactionType": "AUTH",
      "merchantTransactionSecureType": "AUTO",
      "apiVersion": 1,
      "orderReference": `${user?.user?.active_client}_${Date.now()}`,
      "orderDate": Date.now(),
      "serviceUrl": "https://cp.netengi.com/backend/api/billing/gateway/wayforpay/add_card",
      "amount": "1",
      "currency": "UAH",
      "productName": ["249882"],
      "productPrice": ["1"],
      "productCount": ["1"],
      "holdTimeout": 3000,
      "clientFirstName": user?.user?.clients?.[0]?.first_name,
      "clientLastName": user?.user?.clients?.[0]?.last_name,
      "clientEmail": user?.user?.email,
      "clientPhone": user?.user?.clients?.[0]?.phone,
      "clientCountry": user?.user?.clients?.[0]?.country

    }
    console.log('data',data);
    let signature = generateSignature(data);
    data.merchantSignature = signature
    wayforpay.run(data, 
    function (response:any) {
      console.log('approved', response);
    // on approved				 			
    }, 			
    function (response:any) {
      console.log('declined', response);
    // on declined 			
    }, 			
    function (response:any) {
      console.log('pending or in processing 	', response);
    // on pending or in processing 			
    });
  }
    
  const onSubmit = (values:any) => {
    const data = {
      positive_balance: posBalance,
      complete_proforma: completeProforma,
      amount: values.amount,
    }
    billingService.updateAutopayConfig(data)
    .then((res) => {
      console.log('res', res);
      // setCompleteProforma()
      // setPosBalance()

    })
    .catch((err) => {
      viewAlert({
        severity: 'error',
        message: err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
      })
    })
  }

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      amount: yup.string().required(`${t("REQUIRED_FIELD")}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  if(pageLoading) return (
    <>
      <TopProgress loading={pageLoading}/>
      <Skeleton
        baseColor={theme.palette[300]}
        highlightColor={theme.palette[20]}
        width={125} style={{marginBottom:"5px"}} height={30}
      />
      <TableSkeleton
        rows={10}
        columns={5}
        // columnsData={Columns}
      />
    </>
  )



        
  return (
    <Container>
      <TitleWrapper style={{gap:'14px', marginBottom:'20px'}}>
        <HeadLine1>
          {t("CONFIGURE_AUTOPAY")}
        </HeadLine1>
        {/* <Toggle title={t("ENABLED_AUTOPAY")} value={isPaymentMethod} onChange={() => setIsPaymentMethod(!isPaymentMethod)}/> */}
        {/* {isPaymentMethod && */}
          <AutoPayContainer>
            <Input
              required
              title={`${t('AUTO_CREDIT_DEBIT_SUM')} ${user.user.clients[0]?.currency}`} 
              fieldName='amount'
              placeholder={''}
              type='number'
              containerCustom={{width:"100%"}}
              onBlur={handleBlur}
              {...formikProps}
            />
            <CheckBox
              title={t("POSITIVE_BALANCE")}
              toolTip={true}
              toolTipText={`${t("POSITIVE_BALANCE_DESCRIPTION")}`}
              value={posBalance}
              customStyles={{width:"100%", marginBottom:'7px'}}
              onChange={() => setPosBalance(!posBalance)}
            />
            {/* <CheckBox
              title={t("COMPLETE_PROFORMA")}
              toolTip={true}
              toolTipText={`${t("COMPLETE_PROFORMA_DESCRIPTION")}`}
              value={completeProforma}
              onChange={() => setCompleteProforma(!completeProforma)}
            /> */}
            <CheckBox
              customStyles={{width:"100%", marginBottom:'7px'}}
              title={t("SUSPEND_PAY")}
              toolTip={true}
              toolTipText={`${t("SUSPEND_PAY_DESCRIPTION")}`}
              value={suspendPay}
              onChange={() => setSuspendPay(!suspendPay)}
            />
            <Button
              title={t("SAVE_SETTINGS")}
              onClick={handleSubmit}
              variant={'primary'}
              customStyles={{whiteSpace:'nowrap'}}
              size="display"
            />
          </AutoPayContainer>
        {/* // } */}
      </TitleWrapper>
      <TitleWrapper>
        <HeadLine1 >
          {t("PAYMENT_METHODS")}
        </HeadLine1>
      </TitleWrapper>
      <SubTitleWrapper>
        {/* //@ts-ignore */}
        <Typography customStyles={{color:theme.grey[700]}} variant="body">
          {t("PAYMENT_SUBTITLE")}
        </Typography>
        <Button
          title={t("ADD_PAYMENT_METHOD")}
          onClick={() => pay()}
          variant={'primary'}
          customStyles={{whiteSpace:'nowrap'}}
          size="display"
        />
      </SubTitleWrapper>


      {invoices?.length > 0 ?
        <Table
          columns={ColumnsInvoices}
          rows={invoices}
          settings={items}
          hideOther
          hideCheckbox
          paginationData={paginationData}
        />
      :
        <Typography variant="body">
          {t("NO_PAYMENT_METHODS")}
        </Typography>
      }
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        // loading={loading}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        confirmButtonText={t("CANCEL")}
        cancelButtonText={t("CONFIRM")}
        instance={selectedCard}
      />
    </Container>
  )
}

export default PaymentMethods

